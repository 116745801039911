<template>
  <div class="page-info-content">
    <!-- <h3>发票详情</h3> -->
    <Details-page
      :column="2"
      :details-title="'发票信息'"
      :detail-obj="detailsConfig.data"
      :details-item-arr="detailsConfig.detailsItem"
    />
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage2.vue'
import { invoiceIssureInvoiceDetail } from '@/api/shoppingMall'
import { timestampToTime } from '@/utils/util'
export default {
  components: { DetailsPage },
  data() {
    return {
      detailsConfig: {
        detailsItem: [
          { label: '订单编号', value: 'busiNo' },
          { label: '申请时间', value: 'applyTime' },
          { label: '开票日期', value: 'openTime' },
          { label: '发票状态', value: 'state', child: [{ val: '开票中', id: '01' }, { val: '已开票', id: '02' }] },
          { label: '发票内容', value: 'invoiceContent' },
          { label: '发票代码/发票号码', value: 'invoiceCodeNo' },
          { label: '发票总金额/税率', value: 'invoiceAmtAndTaxRate' },
          { label: '购买方', value: 'recvCmpNameUnicode' },
          { label: '销售方', value: 'openCmpNameUnicode' },
          { label: '发票类型', value: 'invoiceType', child: [{ val: '增值税普通发票', id: '00' }, { val: '已开票', id: '01' }] },
          { label: '发票介质', value: 'receiveType', child: [{ val: '电子', id: '00' }, { val: '纸质', id: '01' }] },
          { label: '发票附件', imageSrc: 'fdfsUrl', imageName: 'fdfsName' }
        ],
        data: {}
      }
    }
  },
  created() {
    this.getDetails()
  },
  methods: {
    getDetails() {
      if (this.$route.query.id || this.$route.query.busiId) {
        invoiceIssureInvoiceDetail({ id: this.$route.query.id || '', busiId: this.$route.query.busiId || '' }, (res) => {
          this.detailsConfig.data = { ...res.data }
          this.detailsConfig.data.applyTime = timestampToTime(res.data.applyTime)
          this.detailsConfig.data.invoiceAmtAndTaxRate = res.data.invoiceAmt + ' / ' + res.data.taxRate
          this.detailsConfig.data.invoiceCodeNo = res.data.invoiceCode + ' / ' + res.data.invoiceNo
          this.detailsConfig.data.recvCmpNameUnicode = res.data.recvCmpName + ' ' + res.data.cmpUnicode
          this.detailsConfig.data.openCmpNameUnicode = res.data.openCmpName + ' ' + res.data.openCmpUnicode
          this.detailsConfig.data.recNameTel = res.data.recName + ' ' + res.data.recTel
          if (res.data.receiveType === '01') {
            this.detailsConfig.detailsItem.splice(11, 0, { label: '收票联系人', value: 'recNameTel' }, { label: '收票地址', value: 'recAddr' }, { label: '快递单号', value: 'courierNumber' })
          }
          if (this.detailsConfig.data.fdfsUrl.split('.')[1] === 'pdf') {
            this.detailsConfig.detailsItem.splice(this.detailsConfig.detailsItem.length - 1, 1, { label: '发票附件', pdfUrl: 'fdfsUrl', pdfName: 'fdfsName' })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-descriptions {
  padding: 0 !important;
}
</style>
